// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-post-1-tsx": () => import("./../src/pages/post1.tsx" /* webpackChunkName: "component---src-pages-post-1-tsx" */),
  "component---src-pages-post-2-tsx": () => import("./../src/pages/post2.tsx" /* webpackChunkName: "component---src-pages-post-2-tsx" */),
  "component---src-pages-post-3-tsx": () => import("./../src/pages/post3.tsx" /* webpackChunkName: "component---src-pages-post-3-tsx" */),
  "component---src-pages-post-4-tsx": () => import("./../src/pages/post4.tsx" /* webpackChunkName: "component---src-pages-post-4-tsx" */),
  "component---src-pages-post-5-tsx": () => import("./../src/pages/post5.tsx" /* webpackChunkName: "component---src-pages-post-5-tsx" */),
  "component---src-pages-post-9-tsx": () => import("./../src/pages/post9.tsx" /* webpackChunkName: "component---src-pages-post-9-tsx" */)
}

